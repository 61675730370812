@font-face {
    font-family: "icomoon";
    src: url("/fonts/icomoon.eot?jccpzb");
    src: url("/fonts/icomoon.eot?jccpzb#iefix") format("embedded-opentype"),
        url("/fonts/icomoon.ttf?jccpzb") format("truetype"), url("/fonts/icomoon.woff?jccpzb") format("woff"),
        url("/fonts/icomoon.svg?jccpzb#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon-github:before {
    font-family: "icomoon";
    content: "\eab0";
}

.icon-linkedin:before {
    font-family: "icomoon";
    content: "\eac9";
}

.icon-mail:before {
    font-family: "icomoon";
    content: "\ea86";
  }
