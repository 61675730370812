* {
    margin: 0;
    padding: 0;
}

body {
    background: #ebdab1;
    font-family: Lato;
    background: url("/images/img.jpg") 50% bottom;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
    font-size: 24px;

    @media(max-width: 960px) {
        font-size: 18px;
    }

    @media(max-width: 600px) {
        font-size: 12px;
    }
}

main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-around;

    background: rgba(0, 0, 0, 0.6);

    min-height: 100vh;
}

h1 {
    font-size: 4em;
    font-weight: 300;
    padding: 5px 0 20px 5px;

    text-align: center;
}

h2 {
    font-size: 1.6em;
    font-weight: 300;
    padding: 5px 0 20px 5px;

    text-align: center;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: center;

    a {
        display: block;
        max-width: 40%;

        margin: 0 2%;

        display: inline-block;

        color: #fff;

        text-decoration: none;

        font-size: 3em;

        &:focus,
        &:hover {
            color: #B4A173
        }
    }
}

.contact {
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(0,0,0,.8);
}
